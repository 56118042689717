import React from "react";
import { Redirect } from "react-router-dom"
import { useAsync } from "react-async";
import DocumentationAPI from "../api/DocumentationAPI";
import defaultLogo from "./../logo.svg";
import DocPortal from "./DocPortal/DocPortal";
import FullPageLoading from "./FullPageLoading";
import AvailablePortals from "./Portal/AvailablePortals";
import { useAuth } from "../contexts/AuthContext";

const fetchDocumentation = () => DocumentationAPI.get();
const fetchPublicDocumentation = () => DocumentationAPI.getPublic();
export default function Portal() {
  const { user, isOrganizationAdmin = false } = useAuth();
  const { data: documentation, isLoading, error } = useAsync(
    user ? fetchDocumentation : fetchPublicDocumentation
  );


  if (error && error.response) {
    if (error.response.status === 400) {
      return <AvailablePortals portals={error.response.data.portals} />;
    }
    return <Redirect to="/login" />
  }
  if (isLoading) {
    return <FullPageLoading label="Fetching your portal..." />;
  }
  
  return (
    <>
      {documentation && (
        <DocPortal
          logo={documentation.logo || defaultLogo}
          documentation={documentation}
          isAdmin={isOrganizationAdmin}
        />
      )}
    </>
  );
}
