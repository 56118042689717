import React, { Component } from "react";
import MdToJsx from "markdown-to-jsx";
import PropTypes from "prop-types";

export default class Markdown extends Component {
  static propTypes = {
    md: PropTypes.string.isRequired
  };

  render() {
    return <MdToJsx className="doc-portal-content">{this.props.md}</MdToJsx>;
  }
}
