import React from "react";
import { FaTimes, FaSearch } from "react-icons/fa";

export default function SearchBar({ search, onChange }) {
  const onInputChange = ({ target }) => onChange(target.value);
  const hasSearch = search !== "";
  const SearchIcon = hasSearch ? FaTimes : FaSearch;
  const onClick = () => {
    if (hasSearch) {
      onChange("");
    }
  };
  return (
    <div className="doc-portal-search">
      <input
        type="text"
        placeholder="Search..."
        value={search}
        onChange={onInputChange}
      />

      <SearchIcon onClick={onClick} className="doc-portal-search-icon" />
    </div>
  );
}
