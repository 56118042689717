import {
  Button,
  Dialog,
  DialogActions,
  IconButton,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import {
  Delete as DeleteIcon,
  Visibility as VisibilityIcon
} from "@material-ui/icons";
import MdToJsx from "markdown-to-jsx";
import React, { useState } from "react";
import { FaMarkdown as MarkdownIcon } from "react-icons/fa";

export default function Markdown({ markdown, onDeleteClick }) {
  const classes = useStyles();
  const [showPreview, setShowPreview] = useState(false);
  const togglePreview = () => {
    setShowPreview(!showPreview);
  };
  return (
    <div className={classes.main}>
      <div className={classes.icon}>
        <MarkdownIcon />
      </div>
      <div className={classes.content}>
        <Typography className={classes.title}>{markdown.title}</Typography>
        <div>
          <Tooltip title="See">
            <IconButton aria-label="See" onClick={togglePreview}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" onClick={onDeleteClick}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <Dialog open={showPreview} onClose={togglePreview}>
        <div className={classes.preview}>
          <MdToJsx>{markdown.content}</MdToJsx>
        </div>
        <DialogActions>
          <Button variant="outlined" onClick={togglePreview} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: "1.9rem",
    height: "2.2rem",
    marginRight: theme.spacing(2)
  },
  main: {
    display: "flex",
    alignItems: "center",
    borderLeft: "3px solid #E8ECF0",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  title: {
    paddingLeft: theme.spacing(1.5)
  },
  content: {
    display: "flex",
    flexDirection: "column",
    color: grey[600]
  },
  preview: {
    padding: theme.spacing(2),
    minWidth: 500,
    minHeight: 500
  }
}));
