import React, { useState, createContext, useEffect, useContext } from "react";
import UserAPI from "../api/UserAPI";
import AccountAPI from "../api/AccountAPI";
import FullPageLoading from "../components/FullPageLoading";

export const AuthContext = createContext({
  isLoggedIn: false,
  user: null,
  login: () => {},
  logout: () => {}
});

function logout() {
  return AccountAPI.logout();
}

export default function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isOrganizationAdmin, setIsOrganizationAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  async function login(email, password, token) {
    try {
      await AccountAPI.login(email, password, token);
    } catch (err) {
      throw new Error("Incorrect email or password");
    }

    await fetchUser();
  }

  async function fetchUser() {
    try {
      const { user, isOrganizationAdmin } = await UserAPI.getCurrent();
      setUser(user);
      setIsOrganizationAdmin(isOrganizationAdmin);
    } catch (err) {
      setUser(null);
      throw new Error(err.response?.data?.message ?? err.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    async function getUser() {
      try {
        await fetchUser();
      } catch (err) {
        // Fail silently
      }
    }
    getUser();
  }, []);

  if (isLoading) {
    return <FullPageLoading label="Authenticating..." />;
  }
  return (
    <AuthContext.Provider value={{ user, isOrganizationAdmin, login, logout }}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
