import BaseAPI from "./BaseAPI";

/**
 * Account API
 * @extends BaseAPI
 */
export default class AccountAPI extends BaseAPI {
  static login(username, password, token) {
    return this.execute("post", "/accounts/login", { username, password, token });
  }

  static logout() {
    return this.execute("delete", "/accounts/logout");
  }
}
