import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles
} from "@material-ui/core";
import React, { useState } from "react";
import { useAsync } from "react-async";
import MarkdownAPI from "../../../api/MarkdownAPI";

const fetchMarkdowns = () => MarkdownAPI.all();

export default function MarkdownPicker({ match, history, onClose, onSave }) {
  const classes = useStyles();
  const { data: markdowns = [] } = useAsync(fetchMarkdowns);
  const [selectedMarkdown, setSelectedMarkdown] = useState("");

  const handleChange = ({ target }) => {
    setSelectedMarkdown(target.value);
  };
  const handleSave = () => {
    const markdown = markdowns.find(({ _id }) => _id === selectedMarkdown);
    if (markdown) {
      onSave(markdown);
    }
  };
  const redirectToCreate = () => {
    history.push(`/admin/portals/${match.params.id}/markdowns/create`);
  };
  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add markdown</DialogTitle>
      <DialogContent>
        <DialogContentText>Pick from an existing one :</DialogContentText>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="markdown">Markdown</InputLabel>
          <Select
            value={selectedMarkdown}
            onChange={handleChange}
            inputProps={{ name: "markdown", id: "markdown" }}
          >
            {markdowns.map(markdown => (
              <MenuItem key={markdown._id} value={markdown._id}>
                {markdown.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <DialogContentText>Or create a new one</DialogContentText>
        <Button variant="outlined" onClick={redirectToCreate} color="primary">
          Create
        </Button>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={selectedMarkdown === ""}
          onClick={handleSave}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 300
  }
}));
