import BaseAPI from "./BaseAPI";

/**
 * Documentation API
 * @extends BaseAPI
 */
export default class DocumentationAPI extends BaseAPI {
  /**
   * Get documentation for current organization
   *
   * @returns {Promise<Object>} Documentation
   */
  static get() {
    return this.execute("get", "/documentation");
  }

  /**
   * Get documentation for current organization
   *
   * @returns {Promise<Object>} Documentation
   */
  static getPublic() {
    return this.execute("get", "/public-documentation");
  }
}
