import {
  Button,
  Card,
  Container,
  Divider,
  makeStyles,
  Typography
} from "@material-ui/core";
import React, { useState } from "react";
import { useAsync } from "react-async";
import VersionAPI from "../../../api/VersionAPI";
import Head from "../Head";
import MarkdownPicker from "../Markdowns/MarkdownPicker";
import Markdown from "./../Markdowns/Markdown";
import Spec from "./Spec";
import SpecPicker from "./SpecPicker";

const loadVersion = ({ resourceId, versionId }) =>
  VersionAPI.get(resourceId, versionId);

export default function ConfigVersion({ history, match }) {
  const { resourceId, versionId } = match.params;

  const [markdowns, setMarkdowns] = useState([]);
  const [spec, setSpec] = useState(null);
  const [editingSpec, setEditingSpec] = useState(null);
  const [versionName, setVersionName] = useState("");
  const [showMarkdownPicker, setShowMarkdownPicker] = useState(false);
  const [showSpecPicker, setShowSpecPicker] = useState(false);

  useAsync(loadVersion, {
    onResolve: version => {
      setMarkdowns(version.markdowns || []);
      setSpec(version.spec || null);
      setVersionName(version.name);
    },
    resourceId,
    versionId
  });

  const addMarkdown = markdown => {
    setMarkdowns([...markdowns, markdown]);
    setShowMarkdownPicker(false);
  };

  const removeMarkdown = markdownId => {
    setMarkdowns(markdowns.filter(({ _id }) => _id !== markdownId));
  };

  const addSpec = spec => {
    setSpec(spec);
    setShowSpecPicker(false);
  };

  const editSpec = spec => {
    setEditingSpec(spec);
    setShowSpecPicker(true);
  };

  const onSubmit = async event => {
    event.preventDefault();
    await VersionAPI.update(resourceId, versionId, { markdowns, spec });
    history.goBack();
  };

  const classes = useStyles();
  return (
    <>
      <Head>Configure version {versionName}</Head>
      <Container>
        <Card className={classes.home}>
          <Typography component="h1" variant="h5">
            Markdowns
          </Typography>

          <form className={classes.form} noValidate onSubmit={onSubmit}>
            {markdowns.map(markdown => (
              <Markdown
                key={markdown._id}
                markdown={markdown}
                onDeleteClick={() => removeMarkdown(markdown._id)}
              />
            ))}

            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowMarkdownPicker(true)}
            >
              Add Markdown
            </Button>
            <Divider className={classes.spacer} />

            <Typography component="h1" variant="h5">
              Swagger spec
            </Typography>

            {spec && (
              <Spec
                spec={spec}
                onEditClick={() => editSpec(spec)}
                onDeleteClick={() => setSpec(null)}
              />
            )}

            {!spec && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowSpecPicker(true)}
              >
                Add Spec
              </Button>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Save
            </Button>
            {showMarkdownPicker && (
              <MarkdownPicker
                match={match}
                history={history}
                onSave={addMarkdown}
                onClose={() => setShowMarkdownPicker(false)}
              />
            )}
            {showSpecPicker && (
              <SpecPicker
                defaultSpec={editingSpec}
                onSave={addSpec}
                onClose={() => setShowSpecPicker(false)}
              />
            )}
          </form>
        </Card>
      </Container>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  home: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column"
  },
  spacer: {
    margin: theme.spacing(3, 0)
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));
