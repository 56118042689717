import {
  Card,
  Checkbox,
  Chip,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@material-ui/core";
import { green, orange } from "@material-ui/core/colors";
import {
  Delete,
  Done as DoneIcon,
  Edit,
  PlusOne,
  Warning as WarningIcon
} from "@material-ui/icons";
import React, { useState } from "react";
import VersionAPI from "../../../api/VersionAPI";
import EditableVersion from "./EditableVersion";
import VersionRow from "./VersionRow";

export default function Resource({
  resource,
  isPublished,
  isChecked,
  onCheck,
  onChange,
  onDelete,
  history,
  match
}) {
  const classes = useStyles();

  const [newVersion, setNewVersion] = useState(false);
  const editResource = id => history.push(`${match.url}/resources/${id}/edit`);

  const configureVersion = version =>
    history.push(
      `${match.url}/resources/${resource._id}/versions/${version._id}/configure`
    );

  const saveNewVersion = async version => {
    await VersionAPI.create(resource._id, version);
    setNewVersion(false);
    onChange();
  };

  const deleteVersion = async version => {
    await VersionAPI.delete(resource._id, version._id);
    onChange();
  };

  const updateVersion = async version => {
    await VersionAPI.update(resource._id, version._id, version);
    onChange();
  };

  return (
    <Card className={classes.resource}>
      <div className={classes.resourceHead}>
        <Checkbox
          className={classes.resourceCheck}
          checked={isChecked}
          color="primary"
          onClick={onCheck}
        />
        <Typography component="h3" variant="h6">
          {resource.name}
        </Typography>
        <div>
          <Tooltip title="Edit">
            <IconButton
              aria-label="Edit"
              onClick={() => editResource(resource._id)}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add version">
            <IconButton
              aria-label="Add version"
              onClick={() => setNewVersion(true)}
            >
              <PlusOne />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              aria-label="Delete"
              onClick={() => onDelete(resource._id)}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className={classes.resourceTags}>
        <Chip
          size="small"
          icon={
            isPublished ? (
              <DoneIcon style={{ color: "#FFF" }} />
            ) : (
              <WarningIcon style={{ color: "#FFF" }} />
            )
          }
          className={
            isPublished ? classes.publishedTag : classes.notPublishedTag
          }
          label={isPublished ? "Published" : "Not published"}
        />
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Version</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resource.versions.map(version => (
            <VersionRow
              key={version._id}
              version={version}
              onChange={updateVersion}
              onConfigure={configureVersion}
              onDelete={deleteVersion}
            />
          ))}
          {newVersion && (
            <EditableVersion
              onSave={saveNewVersion}
              onCancel={() => setNewVersion(false)}
            />
          )}
        </TableBody>
      </Table>
    </Card>
  );
}

const useStyles = makeStyles(theme => ({
  resourceHead: {
    padding: theme.spacing(2, 2, 1, 2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  resourceTags: {
    padding: theme.spacing(0, 1, 1, 2),
    display: "flex",
    justifyContent: "space-between"
  },
  publishedTag: {
    backgroundColor: green[500],
    color: "#FFF",
    fontWeight: 600
  },
  notPublishedTag: {
    backgroundColor: orange[300],
    color: "#FFF",
    fontWeight: 600
  },
  resource: {
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(6),
    marginRight: theme.spacing(3),
    flexDirection: "column",
    width: "345px",
    position: "relative"
  },
  resourceCheck: {
    position: "absolute",
    top: "-10px",
    right: "-10px"
  }
}));
