import {
  Card,
  Container,
  Button,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@material-ui/core";
import {
  Edit as EditIcon,
  Settings as SettingsIcon,
  OpenInNew as OpenIcon
} from "@material-ui/icons";
import React from "react";
import { useAsync } from "react-async";
import PortalAPI from "./../../api/PortalAPI";
import DeleteButton from "./DeleteButton";
import Head from "./Head";
import { getCurrentDomain, getFullUrl } from "../../utils/domain";

const domain = getCurrentDomain();
const loadPortals = () => PortalAPI.all();

export default function Portals({ history }) {
  const classes = useStyles();
  const { data: portals = [], isLoading, reload } = useAsync(loadPortals);
  const hasPortals = portals.length > 0;

  if (!isLoading && !hasPortals) {
    // Redirect to portal creation if no portals
    history.push("/admin/create");
  }

  const addPortal = () => history.push("/admin/create");
  const editPortal = portalId => {
    history.push(`/admin/${portalId}/edit`);
  };
  const configurePortal = portalId => {
    history.push(`/admin/portals/${portalId}`);
  };
  const deletePortal = async portalId => {
    await PortalAPI.delete(portalId);
    reload();
  };

  const openPortal = async portal => {
    window.open(getFullUrl(`${portal.name}-${domain}`), "_blank");
  };

  return (
    <>
      <Head>Portals</Head>
      <Container>
        <Card className={classes.home}>
          <div className={classes.tableHead}>
            <Typography component="h3" variant="h6">
              Your portals
            </Typography>
            <div className={classes.tableActions}>
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={addPortal}
              >
                Add portal
              </Button>
            </div>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {portals.map(portal => (
                <TableRow key={portal._id}>
                  <TableCell component="th" scope="row">
                    {portal.name}
                  </TableCell>
                  <TableCell align="right" component="th" scope="row">
                    <Tooltip title="Configure">
                      <IconButton
                        aria-label="Configure"
                        onClick={() => configurePortal(portal._id)}
                      >
                        <SettingsIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Open">
                      <IconButton
                        aria-label="Open"
                        onClick={() => openPortal(portal)}
                      >
                        <OpenIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <IconButton
                        aria-label="Edit"
                        onClick={() => editPortal(portal._id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>

                    <DeleteButton onDelete={() => deletePortal(portal._id)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </Container>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  tableHead: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  home: {
    backgroundColor: theme.palette.common.white,

    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column"
  }
}));
