import axios from "axios";

const API_URL = process.env.REACT_APP_API_HOST;
const client = axios.create({
  baseURL: API_URL,
  json: true,
  withCredentials: true
});

export default class BaseAPI {
  /**
   * Execute a HTTP Request
   *
   * @param {String} method The HTTP Method
   * @param {String} resource The Path
   * @param {Object} data An Object that will be stringified
   * @returns {Promise<Object>}
   */
  static async execute(method, resource, data) {
    const options = {
      method,
      url: resource,
      data
    };

    const response = await client(options);
    return response.data;
  }
}
