import React from "react";

import Markdown from "./Markdown";
import ResourcesSummary from "./Home/ResourcesSummary";
import Sidebar from "./Sidebar";
import { capitalize } from "./utils/format";
import { useConfig } from "./context/config";

function Home() {
  const { documentation } = useConfig();
  const { home, resources } = documentation;
  const links = [
    {
      name: "Resources",
      href: null,
      children: resources.map(({ name }) => ({
        name: capitalize(name),
        href: `resources_${name}`
      }))
    }
  ];
  return (
    <>
      <Sidebar links={links} />
      <div className="doc-portal-main doc-portal-container">
        {home.markdowns.map((md, index) => (
          <Markdown key={index} md={md} />
        ))}
        <ResourcesSummary resources={resources} />
      </div>
    </>
  );
}

export default Home;
