import {
  Button,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import withMainTheme from "../WithMainTheme";

function LoginForm(props) {
  const classes = useStyles();
  const [inputs, setInputs] = useState({ email: "", password: "" });

  const onInputChange = ({ target }) => {
    setInputs({ ...inputs, [target.name]: target.value });
  };

  const resetForm = () => {
    setInputs({ email: "", password: "" });
  };

  useEffect(() => {
    resetForm();
  }, [props.refresh]);

  return (
    <form className={classes.form} onSubmit={e => props.onSubmit(e, inputs)}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        type="email"
        autoComplete="email"
        autoFocus
        value={inputs.email}
        onChange={onInputChange}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        value={inputs.password}
        autoComplete="current-password"
        onChange={onInputChange}
      />
      <Alert severity="info">
        Please use your apigrow credentials.
      </Alert>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        Login
      </Button>
    </form>
  );
};

const useStyles = makeStyles(theme => ({
  submit: {
    marginTop: theme.spacing(3)
  }
}));

export default withMainTheme(LoginForm);
