import React, { useContext } from "react";
import { Paper, Typography, makeStyles, Chip } from "@material-ui/core";
import { PortalContext } from "../../contexts/PortalContext";
import { getCurrentDomain, getFullUrl } from "../../utils/domain";
import { OpenInNew as OpenIcon } from "@material-ui/icons";

const domain = getCurrentDomain();

export default function Head({ children }) {
  const { currentPortal } = useContext(PortalContext);
  const classes = useStyles();

  const openPortal = portalUrl => {
    window.open(getFullUrl(portalUrl), "_blank");
  };

  return (
    <Paper className={classes.head}>
      <Typography className={classes.headTitle} component="h1" variant="h5">
        {children}
      </Typography>
      {currentPortal && (
        <div className={classes.portalInfo}>
          For &nbsp;
          <Chip
            className={classes.portalChip}
            variant="outlined"
            color="primary"
            label={`${currentPortal.name}-${domain}`}
            deleteIcon={<OpenIcon className={classes.openIcon} />}
            onDelete={() => openPortal(`${currentPortal.name}-${domain}`)}
          />
        </div>
      )}
    </Paper>
  );
}

const useStyles = makeStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    padding: theme.spacing(2, 4),
    borderRadius: 0,

    display: "flex"
  },
  headTitle: {
    fontWeight: 500,
    flex: 1
  },
  portalInfo: {
    fontSize: "1rem"
  },
  portalChip: {
    fontSize: "0.9rem",
    fontWeight: 500
  },
  openIcon: {
    marginLeft: theme.spacing(0.5)
  }
}));
