import compareVersions from "compare-versions";

export function getLastVersion(versions) {
  return sortVersions(versions)[0];
}

export function sortVersions(versions) {
  return Object.keys(versions)
    .sort(compareVersions)
    .reverse();
}
