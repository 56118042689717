import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import AuthProvider from "./contexts/AuthContext";
import Admin from "./components/Admin";
import Login from "./components/Login";
import Logout from "./components/Logout";
import Portal from "./components/Portal";

function App() {
  return (
    <AuthProvider>
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <PrivateRoute exact path="/logout" component={Logout} />
          <PrivateRoute path="/admin" component={Admin} />
          <Route path="/" component={Portal} />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
