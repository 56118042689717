import {
  Button,
  Card,
  Container,
  makeStyles,
  Snackbar,
  IconButton,
  Typography,
  Divider
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useAsync } from "react-async";
import PortalAPI from "../../api/PortalAPI";
import { PortalContext } from "../../contexts/PortalContext";
import Head from "./Head";
import Markdown from "./Markdowns/Markdown";
import MarkdownPicker from "./Markdowns/MarkdownPicker";
import { Close as CloseIcon } from "@material-ui/icons";
import { getBase64, resize } from "../../utils/image";

const fetchPortal = ({ portalId }) => PortalAPI.get(portalId);
export default function Homepage({ history, match }) {
  const classes = useStyles();
  const { currentPortal } = useContext(PortalContext);
  const [markdowns, setMarkdowns] = useState([]);
  const [logo, setLogo] = useState(null);
  const [showMarkdownPicker, setShowMarkdownPicker] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Fetch most recent portal as currentPortal is not updated throughout configuration
  const { reload } = useAsync(fetchPortal, {
    onResolve: portal => {
      setMarkdowns(portal.homepage.markdowns);
      setLogo(portal.homepage.logo);
    },
    portalId: currentPortal._id
  });

  const addMarkdown = markdown => {
    setMarkdowns([...markdowns, markdown]);
    setShowMarkdownPicker(false);
  };

  const removeMarkdown = markdownId => {
    setMarkdowns(markdowns.filter(({ _id }) => _id !== markdownId));
  };

  const onSubmit = async event => {
    event.preventDefault();
    await PortalAPI.update(currentPortal._id, {
      homepage: { markdowns, logo }
    });
    setShowSuccessMessage(true);
    reload();
  };

  const upload = async ({ target }) => {
    const file = target.files[0];
    const [base64, type] = await getBase64(file);
    const logo = await resize(base64);

    setLogo(`data:${type};base64,${logo}`);
  };

  return (
    <>
      <Head>Homepage</Head>
      <Container>
        <Card className={classes.home}>
          <form className={classes.form} noValidate onSubmit={onSubmit}>
            <Typography component="h1" variant="h5" className={classes.title}>
              Logo
            </Typography>
            {logo && <img alt="logo" src={logo} className={classes.logo} />}
            <Button variant="contained" color="primary" component="label">
              Upload image
              <input
                accept="image/*"
                className={classes.inputFile}
                id="raised-button-file"
                type="file"
                onChange={upload}
              />
            </Button>
            <Divider className={classes.divider} />
            <Typography component="h1" variant="h5" className={classes.title}>
              Markdowns
            </Typography>
            {markdowns.map(markdown => (
              <Markdown
                key={markdown._id}
                markdown={markdown}
                onDeleteClick={() => removeMarkdown(markdown._id)}
              />
            ))}
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowMarkdownPicker(true)}
            >
              Add Markdown
            </Button>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Save
            </Button>
          </form>

          {showMarkdownPicker && (
            <MarkdownPicker
              match={match}
              history={history}
              onSave={addMarkdown}
              onClose={() => setShowMarkdownPicker(false)}
            />
          )}
        </Card>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={showSuccessMessage}
          autoHideDuration={3000}
          onClose={() => setShowSuccessMessage(false)}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">Homepage updated</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={() => setShowSuccessMessage(false)}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </Container>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  home: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column"
  },
  progress: {
    marginLeft: "45%"
  },
  logo: {
    width: "175px",
    display: "flex",
    marginBottom: theme.spacing(2)
  },
  form: {
    width: "100%"
  },
  inputFile: {
    display: "none"
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  title: {
    margin: theme.spacing(2, 0)
  },
  divider: {
    margin: theme.spacing(3, 0)
  }
}));
