import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./DocPortal.scss";
import "swagger-ui-react/swagger-ui.css";

import Home from "./Home";
import Resource from "./Resource";
import ScrollToTop from "./ScrollToTop";
import ConfigContext from "./context/config";

function DocPortal({ logo, documentation, isAdmin }) {
  return (
    <div className="doc-portal">
      <ConfigContext.Provider value={{ logo, documentation, isAdmin }}>
        <Router>
          <ScrollToTop>
            <Route path="/" exact component={Home} />
            <Route path="/resources/:name/:version" component={Resource} />
          </ScrollToTop>
        </Router>
      </ConfigContext.Provider>
    </div>
  );
}

DocPortal.propTypes = {
  logo: PropTypes.string.isRequired,
  documentation: PropTypes.shape({
    home: PropTypes.shape({
      markdowns: PropTypes.arrayOf(PropTypes.string)
    }),
    resources: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        versions: PropTypes.object
      })
    )
  }).isRequired
};

export default DocPortal;
