import React, { useState, createContext } from "react";

export const PortalContext = createContext({
  currentPortal: null,
  setCurrentPortal: () => {}
});

export default function PortalProvider({ children }) {
  const [currentPortal, setCurrentPortal] = useState(null);

  return (
    <PortalContext.Provider value={{ currentPortal, setCurrentPortal }}>
      {children}
    </PortalContext.Provider>
  );
}
