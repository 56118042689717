import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useAuth } from "./../../contexts/AuthContext";

export default ({ component: Component, ...rest }) => {
  const { isOrganizationAdmin } = useAuth();
  const history = useHistory();
  return (
    <Route
      {...rest}
      render={props =>
        isOrganizationAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: history.location.pathname,
                message:
                  "Unauthorized, you must be an admin of your organization."
              }
            }}
          />
        )
      }
    />
  );
};
