import React, { useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import FullPageLoading from "./FullPageLoading";

export default function Logout({ history }) {
  const { logout } = useAuth();
  useEffect(() => {
    logout();
    history.push("/login");
  }, [history, logout]);
  return <FullPageLoading label="Logging out..." />;
}
