import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  makeStyles
} from "@material-ui/core";
import React, { useState } from "react";
import ModelAPI from "../../../api/ModelAPI";
import { useAsync } from "react-async";

const loadModels = () => ModelAPI.all();
export default function SyncModels({ onClose, onSync }) {
  const classes = useStyles();
  const [modelsToSync, setModelsToSync] = useState([]);

  const { data: models = [] } = useAsync(loadModels, {
    onResolve: models => setModelsToSync(models)
  });

  const toggleModel = model => {
    if (modelsToSync.some(({ id }) => model.id === id)) {
      setModelsToSync(modelsToSync.filter(({ id }) => model.id !== id));
    } else {
      setModelsToSync([...modelsToSync, model]);
    }
  };

  const syncModels = async () => {
    await ModelAPI.sync(modelsToSync);
    onSync();
  };
  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Sync models from apigrow
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Synchronize your apigrow models to your resources
        </DialogContentText>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell align="right">Tags</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {models.map(model => {
              const isSelected = modelsToSync.some(({ id }) => model.id === id);

              return (
                <TableRow
                  hover
                  key={model.id}
                  onClick={() => toggleModel(model)}
                  role="checkbox"
                  aria-checked={isSelected}
                  selected={isSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isSelected}
                      color="primary"
                      onChange={() => toggleModel(model)}
                    />
                  </TableCell>
                  <TableCell scope="row">{model.name}</TableCell>
                  <TableCell align="right">
                    {model.tags.map(tag => (
                      <Chip
                        size="small"
                        key={tag}
                        className={classes.tag}
                        label={tag}
                        color="primary"
                      />
                    ))}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={modelsToSync.length === 0}
          onClick={syncModels}
          color="primary"
          variant="outlined"
        >
          Synchronize
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  tag: {
    margin: theme.spacing(0.5)
  }
}));
