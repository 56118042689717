import {
  Button,
  Container,
  Fab,
  IconButton,
  makeStyles,
  Snackbar,
  Typography
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import {
  Navigation as NavigationIcon,
  Close as CloseIcon
} from "@material-ui/icons";
import React, { useContext, useState } from "react";
import { useAsync } from "react-async";
import PortalAPI from "../../api/PortalAPI";
import ResourceAPI from "../../api/ResourceAPI";
import { PortalContext } from "../../contexts/PortalContext";
import Head from "./Head";
import Resource from "./Resources/Resource";
import SyncModels from "./Resources/SyncModels";

const loadResources = ({ portalId }) =>
  Promise.all([ResourceAPI.all(), PortalAPI.get(portalId)]);

export default function Resources({ history, match, location }) {
  const classes = useStyles();
  const { currentPortal } = useContext(PortalContext);
  const [showSync, setShowSync] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [resourcesToPublish, setResourcesToPublish] = useState([]);

  const { data = [], reload } = useAsync(loadResources, {
    onResolve: ([resources, portal]) => {
      // If portal has no resources check every existing resources
      // else check only portal resources
      const resourcesToPublish =
        portal.resources.length > 0
          ? portal.resources
          : resources.map(r => r._id);
      setResourcesToPublish(resourcesToPublish);
      if (resources.length === 0 && location.state && location.state.showSync) {
        setShowSync(true);
      }
    },
    portalId: currentPortal._id
  });

  const [resources = [], portal = { resources: [], name: "" }] = data;
  const hasResources = resources.length > 0;

  const addResource = () => history.push(`${match.url}/resources/create`);
  const deleteResource = async id => {
    await ResourceAPI.delete(id);
    reload();
  };

  const onModelsSync = () => {
    setShowSync(false);
    reload();
  };

  const toggleResource = (resourceId, { target }) => {
    if (target.checked) {
      setResourcesToPublish([...resourcesToPublish, resourceId]);
    } else {
      setResourcesToPublish(resourcesToPublish.filter(id => id !== resourceId));
    }
  };

  const publish = async () => {
    await PortalAPI.update(currentPortal._id, {
      resources: resourcesToPublish
    });
    setShowSuccessMessage(true);
    reload();
  };

  return (
    <>
      <Head>Resources</Head>
      <Container>
        <div className={classes.resourcesActions}>
          <Button variant="contained" color="primary" onClick={addResource}>
            Add Resource
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowSync(true)}
          >
            Sync models from apigrow
          </Button>
        </div>
        {hasResources && (
          <div className={classes.resourcesList}>
            {resources.map(resource => {
              const isPublished = portal.resources.includes(resource._id);
              const isChecked = resourcesToPublish.includes(resource._id);
              return (
                <Resource
                  key={resource._id}
                  history={history}
                  match={match}
                  resource={resource}
                  isPublished={isPublished}
                  isChecked={isChecked}
                  onCheck={e => toggleResource(resource._id, e)}
                  onChange={reload}
                  onDelete={deleteResource}
                />
              );
            })}
          </div>
        )}
        {!hasResources && (
          <Typography component="h5" variant="h6">
            <i>No resources yet</i>
          </Typography>
        )}

        {showSync && (
          <SyncModels
            onClose={() => setShowSync(false)}
            onSync={onModelsSync}
          />
        )}
        <Fab
          className={classes.publishButton}
          variant="extended"
          onClick={publish}
          color="primary"
        >
          <NavigationIcon className={classes.publishIcon} />
          Publish to {portal.name} portal ({resourcesToPublish.length})
        </Fab>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={showSuccessMessage}
          autoHideDuration={3000}
          onClose={() => setShowSuccessMessage(false)}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">Resources published</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={() => setShowSuccessMessage(false)}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </Container>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  resourcesActions: {
    display: "flex",
    margin: theme.spacing(3, 0),
    "& button": {
      marginRight: theme.spacing(3)
    }
  },
  resourcesList: {
    display: "flex",
    flexWrap: "wrap"
  },

  publishButton: {
    margin: 0,
    top: "auto",
    left: "45%",
    bottom: theme.spacing(5),
    right: "auto",
    position: "fixed",
    backgroundColor: green[500],
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  publishIcon: {
    marginRight: theme.spacing(1)
  }
}));
