import React from "react";
import {
  Avatar,
  Chip,
  Container,
  Typography,
  makeStyles,
  Card,
  Button
} from "@material-ui/core";
import { Public as PublicIcon } from "@material-ui/icons";
import withMainTheme from "./../WithMainTheme";
import { getCurrentDomain, getFullUrl } from "../../utils/domain";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";

function AvailablePortals({ portals }) {
  const history = useHistory();
  const classes = useStyles();
  const goToAdmin = () => history.push("/admin");
  const { isOrganizationAdmin } = useAuth();
  const domain = getCurrentDomain();
  return (
    <Container component="main" maxWidth="xs">
      <Card className={classes.main}>
        <Avatar className={classes.avatar}>
          <PublicIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Choose your portal
        </Typography>
        <div className={classes.portals}>
          {portals.map(portal => (
            <a
              key={portal}
              href={getFullUrl(`${portal}-${domain}`)}
              className={classes.portalLink}
            >
              <Chip
                variant="outlined"
                color="primary"
                style={{ cursor: "pointer" }}
                label={`${portal}-${domain}`}
              />
            </a>
          ))}
        </div>
        {isOrganizationAdmin && (
          <Button
            variant="contained"
            color="primary"
            className={classes.adminButton}
            onClick={() => goToAdmin()}
          >
            Create new portal
          </Button>
        )}
      </Card>
    </Container>
  );
}

const useStyles = makeStyles(theme => ({
  main: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  portals: {
    display: "flex",
    flexDirection: "column"
  },
  adminButton: {
    marginTop: theme.spacing(2)
  },
  portalLink: {
    textDecoration: "none",
    marginTop: theme.spacing(3)
  }
}));
export default withMainTheme(AvailablePortals);
