import {
  Button,
  Card,
  Container,
  makeStyles,
  TextField
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MarkownAPI from "../../../api/MarkdownAPI";
import Head from "../Head";
import MarkdownEditor from "../MarkdownEditor";

export default function EditMarkdown({ history, match }) {
  const isEditing = match.params.markdownId || false;
  const markdownId = isEditing ? match.params.markdownId : null;
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    async function fetchMarkdown() {
      const markdown = await MarkownAPI.get(markdownId);
      setTitle(markdown.title);
      setContent(markdown.content);
    }
    if (markdownId) {
      fetchMarkdown();
    }
  }, [markdownId]);

  const onSubmit = async event => {
    event.preventDefault();
    if (isEditing) {
      await MarkownAPI.update(markdownId, { title, content });
    } else {
      await MarkownAPI.create({ title, content });
    }

    history.goBack();
  };

  return (
    <>
      <Head>{isEditing ? "Edit Markdown" : "New Markdown"}</Head>
      <Container>
        <Card className={classes.home}>
          <form className={classes.form} noValidate onSubmit={onSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="title"
              label="Title"
              name="title"
              autoComplete="title"
              autoFocus
              value={title}
              onChange={({ target }) => setTitle(target.value)}
            />

            <MarkdownEditor
              value={content}
              name="content"
              onChange={({ target }) => setContent(target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Save
            </Button>
          </form>
        </Card>
      </Container>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  home: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column"
  }
}));
