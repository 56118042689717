const { REACT_APP_URL_PATTERN } = process.env;

// Return organization + domain of current url
// Ex: camaieu.portal.local.io
export function getCurrentDomain() {
  const urlPattern = new RegExp(REACT_APP_URL_PATTERN);
  const [, , organization = "", domain = ""] =
    window.location.hostname.match(urlPattern) || [];
  return organization + domain;
}

export function getFullUrl(url) {
  const { location } = window;
  return `${location.protocol}//${url}${
    location.port ? `:${location.port}` : ""
  }`;
}
