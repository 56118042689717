import BaseAPI from "./BaseAPI";

/**
 * Portal API
 * @extends BaseAPI
 */
export default class PortalAPI extends BaseAPI {
  /**
   * Get portals for current organization
   *
   * @returns {Promise<Object[]>} Portal
   */
  static all() {
    return this.execute("get", "/portals");
  }

  /**
   * Get a portal
   *
   * @param {String} id Portal id
   * @returns {Promise<Object>} Portal
   */
  static get(id) {
    return this.execute("get", `/portals/${id}`);
  }

  /**
   * Check portal name availability
   *
   * @param {string} name Portal name
   * @returns {Promise<boolean>} Availability
   */
  static checkAvailability(name) {
    return this.execute("get", `/portals/availability?name=${name}`);
  }

  /**
   * Create a portal
   *
   * @param {Object} portal Portal
   * @returns {Promise<Object>} Created portal
   */
  static create(portal) {
    return this.execute("post", "/portals", portal);
  }

  /**
   * Update a portal
   *
   * @param {string} id Portal id
   * @param {Object} portal Portal update
   * @returns {Promise<Object>} Updated portal
   */
  static update(id, portal) {
    return this.execute("patch", `/portals/${id}`, portal);
  }

  /**
   * Delete a portal
   *
   * @param {string} id Portal id
   * @returns {Promise<Object>}
   */
  static delete(id) {
    return this.execute("delete", `/portals/${id}`);
  }
}
