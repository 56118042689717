import BaseAPI from "./BaseAPI";

/**
 * Resource API
 * @extends BaseAPI
 */
export default class ResourceAPI extends BaseAPI {
  /**
   * Get resources for current organization
   *
   * @returns {Promise<Object[]>} Resources
   */
  static all() {
    return this.execute("get", "/resources");
  }

  /**
   * Get resource
   *
   * @returns {Promise<Object>} Resourc
   */
  static get(id) {
    return this.execute("get", `/resources/${id}`);
  }

  /**
   * Create a resource
   *
   * @returns {Promise<Object>} Resources
   */
  static create(resource) {
    return this.execute("post", "/resources", resource);
  }

  /**
   * Update a resource
   *
   * @returns {Promise<Object>} Resource
   */
  static update(id, resource) {
    return this.execute("put", `/resources/${id}`, resource);
  }

  /**
   * Delete a resource
   *
   * @returns {Promise<Object>} Resource
   */
  static delete(id) {
    return this.execute("delete", `/resources/${id}`);
  }
}
