import BaseAPI from "./BaseAPI";

/**
 * Model API
 * @extends BaseAPI
 */
export default class ModelAPI extends BaseAPI {
  /**
   * Get models from current organization
   *
   * @returns {Promise<Object[]>} Models
   */
  static all() {
    return this.execute("get", "/models");
  }

  /**
   * Sync given models into resources
   *
   * @returns {Promise}
   */
  static sync(models) {
    return this.execute("post", "/models/sync", { models });
  }
}
