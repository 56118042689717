import {
  Button,
  Card,
  Container,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import React from "react";
import { useAsync } from "react-async";
import MarkdownAPI from "../../api/MarkdownAPI";
import Head from "./Head";

const loadMarkdowns = () => MarkdownAPI.all();

export default function Markdowns({ history, match }) {
  const classes = useStyles();
  const { data: markdowns = [], reload } = useAsync(loadMarkdowns);
  const hasMarkdowns = markdowns.length > 0;
  const addMarkdown = () => history.push(`${match.url}/create`);
  const editMarkdown = id => history.push(`${match.url}/${id}/edit`);
  const deleteMarkdown = async id => {
    await MarkdownAPI.delete(id);
    reload();
  };
  return (
    <>
      <Head>Markdowns</Head>
      <Container>
        <Card className={classes.home}>
          <div className={classes.tableHead}>
            <Typography component="h3" variant="h6">
              Your markdowns
            </Typography>
            <div className={classes.tableActions}>
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={addMarkdown}
              >
                Add markdown
              </Button>
            </div>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {markdowns.map(markdown => (
                <TableRow key={markdown._id}>
                  <TableCell component="th" scope="row">
                    {markdown.title}
                  </TableCell>
                  <TableCell align="right" component="th" scope="row">
                    <Tooltip title="Edit">
                      <IconButton
                        aria-label="Edit"
                        onClick={() => editMarkdown(markdown._id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Delete">
                      <IconButton
                        aria-label="Delete"
                        onClick={() => deleteMarkdown(markdown._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {!hasMarkdowns && (
                <TableRow>
                  <TableCell colSpan="3" component="th" scope="row">
                    <i>No Markdowns yet</i>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Card>
      </Container>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  tableHead: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  home: {
    backgroundColor: theme.palette.common.white,

    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column"
  }
}));
