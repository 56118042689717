import BaseAPI from "./BaseAPI";

/**
 * User API
 * @extends BaseAPI
 */
export default class UserAPI extends BaseAPI {
  static getCurrent() {
    return this.execute("get", "/users/me");
  }
}
