import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useConfig } from "./context/config";
import { useAuth } from "../../contexts/AuthContext"
import SidebarLink from "./Sidebar/SidebarLink";
import SearchBar from "./Sidebar/SearchBar";
import FilteredLinks from "./Sidebar/FilteredLinks";

export default function Sidebar({ links, offset = 0 }) {
  const { logo, isAdmin } = useConfig();
  const { user } = useAuth();

  const [search, setSearch] = useState("");
  return (
    <aside className="doc-portal-sidebar">
      <Link to="/">
        <img src={logo} width="175px" className="doc-portal-logo" alt="logo" />
      </Link>
      <SearchBar search={search} onChange={setSearch} />
      <div className="doc-portal-links">
        <FilteredLinks links={links} search={search}>
          {filteredLinks => (
            <>
              {filteredLinks.map(link => (
                <SidebarLink key={link.name} link={link} offset={offset} />
              ))}
            </>
          )}
        </FilteredLinks>
      </div>
      <div className="doc-portal-links doc-portal-bottom-links">
        {!user && (
          <a className="doc-portal-link" href="/login">
            Login
          </a>
        )}
        {user && (
          <a className="doc-portal-link" href="/logout">
            Logout
          </a>
        )}
        {user && isAdmin && (
          <a className="doc-portal-link" href="/admin">
            Admin
          </a>
        )}
      </div>
    </aside>
  );
}
