import React, { useState, useEffect } from "react";
import {
  Chip,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  makeStyles,
  Divider,
  Typography,
  Radio
} from "@material-ui/core";
import ModelAPI from "../../../../api/ModelAPI";

export default function ModelForm({ spec = null, onChange }) {
  const classes = useStyles();
  const [models, setModels] = useState([]);
  const defaultSelectedModels =
    spec && typeof spec.content === "string" ? spec.content : null;
  const [selectedModel, setSelectedModel] = useState(defaultSelectedModels);
  useEffect(() => {
    async function fetchModels() {
      const models = await ModelAPI.all();
      setModels(models);
    }
    fetchModels();
  }, []);

  const selectModel = model => {
    setSelectedModel(model.id);
    onChange({ type: "model", content: model.id });
  };

  return (
    <>
      <Divider className={classes.divider} />
      <Typography variant="h6">Select a model</Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Name</TableCell>
            <TableCell align="right">Tags</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {models.map(model => {
            const isChecked = model.id === selectedModel;
            return (
              <TableRow
                hover
                key={model.id}
                onClick={() => selectModel(model)}
                role="radio"
                aria-checked={isChecked}
                selected={isChecked}
              >
                <TableCell padding="checkbox">
                  <Radio
                    checked={isChecked}
                    color="primary"
                    onChange={() => selectModel(model)}
                  />
                </TableCell>
                <TableCell scope="row">{model.name}</TableCell>
                <TableCell align="right">
                  {model.tags.map(tag => (
                    <Chip
                      size="small"
                      key={tag}
                      className={classes.tag}
                      label={tag}
                      color="primary"
                    />
                  ))}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(2, 0)
  },
  form: {
    display: "flex",
    margin: theme.spacing(1, 0)
  },
  input: {
    flex: 1
  },
  tags: {
    display: "flex",
    flexWrap: "wrap"
  },
  tag: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));
