import React from "react";
import { CircularProgress, Typography, makeStyles } from "@material-ui/core";

export default function FullPageLoading({ label }) {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <CircularProgress size={80} />
      <Typography className={classes.loadingText} color="primary">
        {label}
      </Typography>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  main: {
    overflow: "hidden",
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  loadingText: {
    marginTop: theme.spacing(3)
  }
}));
