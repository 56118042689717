import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import theme from "../styles/MainTheme";
import { CssBaseline } from "@material-ui/core";

export default function withMainTheme(WrappedComponent) {
  const wrappedComponent = ({ ...props }) => (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <WrappedComponent {...props} />
    </ThemeProvider>
  );

  return wrappedComponent;
}
