import BaseAPI from "./BaseAPI";

/**
 * Markdown API
 * @extends BaseAPI
 */
export default class MarkdownAPI extends BaseAPI {
  /**
   * Get all markdowns for current organization
   *
   * @returns {Promise<Object[]>} Markdowns
   */
  static all() {
    return this.execute("get", "/markdowns");
  }

  /**
   * Get Markdown
   *
   * @returns {Promise<Object>} Markdown
   */
  static get(id) {
    return this.execute("get", `/markdowns/${id}`);
  }

  /**
   * Create a Markdown
   *
   * @returns {Promise<Object>} Markdown
   */
  static create(markdown) {
    return this.execute("post", "/markdowns", markdown);
  }

  /**
   * Update a Markdown
   *
   * @returns {Promise<Object>} Markdown
   */
  static update(id, markdown) {
    return this.execute("put", `/markdowns/${id}`, markdown);
  }

  /**
   * Delete a Markdown
   *
   * @returns {Promise<Object>} Markdown
   */
  static delete(id) {
    return this.execute("delete", `/markdowns/${id}`);
  }
}
