import React, { useContext, useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";
import PortalAPI from "../../api/PortalAPI";
import { PortalContext } from "../../contexts/PortalContext";
import FullPageLoading from "../FullPageLoading";
import AdminRoute from "./AdminRoute";
import Homepage from "./Homepage";
import Markdowns from "./Markdowns";
import EditMarkdown from "./Markdowns/EditMarkdown";
import Resources from "./Resources";
import ConfigVersion from "./Resources/ConfigVersion";
import EditResource from "./Resources/EditResource";

export default function ConfigPortal({ history, match }) {
  const { currentPortal, setCurrentPortal } = useContext(PortalContext);
  const { params, path, url } = match;
  const NotFoundRedirect = () => <Redirect to={url} />;

  useEffect(() => {
    async function fetchPortal() {
      try {
        const portal = await PortalAPI.get(params.id);
        setCurrentPortal(portal);
      } catch (err) {
        history.push("/admin");
      }
    }
    fetchPortal();
    return () => setCurrentPortal(null); // Clean currentPortal when not in ConfigPortal subroute
  }, [history, params.id, setCurrentPortal]);

  if (!currentPortal) {
    return <FullPageLoading label="Loading portal..." />;
  }

  return (
    <Switch>
      <AdminRoute exact path={path} component={Resources} />
      <AdminRoute
        exact
        path={`${path}/resources/create`}
        component={EditResource}
      />
      <AdminRoute
        exact
        path={`${path}/resources/:resourceId/edit`}
        component={EditResource}
      />
      <AdminRoute
        exact
        path={`${path}/resources/:resourceId/versions/:versionId/configure`}
        component={ConfigVersion}
      />
      <AdminRoute exact path={`${path}/homepage`} component={Homepage} />
      <AdminRoute exact path={`${path}/markdowns`} component={Markdowns} />
      <AdminRoute
        exact
        path={`${path}/markdowns/create`}
        component={EditMarkdown}
      />
      <AdminRoute
        exact
        path={`${path}/markdowns/:markdownId/edit`}
        component={EditMarkdown}
      />
      <AdminRoute component={NotFoundRedirect} />
    </Switch>
  );
}
