import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  TextField,
  Tooltip,
  IconButton
} from "@material-ui/core";
import { Check as CheckIcon, Cancel as CancelIcon } from "@material-ui/icons";

export default function EditableVersion({
  defaultVersion = { name: "" },
  onSave,
  onCancel
}) {
  const [name, setName] = useState(defaultVersion.name);

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <TextField
          required
          name="Name"
          label="Name"
          type="text"
          id="name"
          autoFocus
          value={name}
          onChange={({ target }) => setName(target.value)}
        />
      </TableCell>
      <TableCell
        align="right"
        component="th"
        scope="row"
        style={{ display: "flex" }}
      >
        <Tooltip title="Save">
          <IconButton
            aria-label="Save"
            type="submit"
            disabled={name === ""}
            onClick={() => onSave({ name })}
          >
            <CheckIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cancel">
          <IconButton aria-label="Cancel" onClick={onCancel}>
            <CancelIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
