import {
  Avatar,
  Card,
  Container,
  makeStyles,
  Typography,
  Snackbar,
  IconButton
} from "@material-ui/core";
import { AccountBox, Close as CloseIcon } from "@material-ui/icons";
import { Recaptcha } from "../components/Recaptcha/recaptcha";
import React, { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import withMainTheme from "./WithMainTheme";
import LoginForm from "./Login/LoginForm";

const { REACT_APP_RECAPTCHA_V3_SITE_KEY } = process.env;

function Login({ history }) {
  const classes = useStyles();
  const { login } = useAuth();
  const [refresh, doRefresh] = useState(false);
  const [recaptcha, setRecaptcha] = useState({ token: "" });
  const [errorMessage, setErrorMessage] = useState(
    history.location?.state?.message ?? null
  );

  const onSubmit = async (event, inputs) => {
    event.preventDefault();
    setErrorMessage(null);
    const { email, password } = inputs;
    const { token } = recaptcha;
    try {
      doRefresh(false);
      await login(email, password, token);
      const redirectTo = history.location.state?.from ?? "/";
      history.push(redirectTo);
    } catch (err) {
      setRecaptcha({ token: "" });
      doRefresh(true);
      setErrorMessage(err.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Card className={classes.login}>
        <Avatar className={classes.avatar}>
          <AccountBox />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <LoginForm onSubmit={onSubmit} refresh={refresh} />
        <div className={classes.recaptcha}>
          <Recaptcha
            action="portal_login"
            sitekey={REACT_APP_RECAPTCHA_V3_SITE_KEY}
            onToken={token => recaptcha.token = token}
          />
        </div>
      </Card>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={errorMessage !== null}
        autoHideDuration={3000}
        onClose={() => setErrorMessage(null)}
        ContentProps={{
          "aria-describedby": "message-id",
          classes: {
            root: classes.error
          }
        }}
        message={<span id="message-id">{errorMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setErrorMessage(null)}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </Container>
  );
}

const useStyles = makeStyles(theme => ({
  login: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  recaptcha: {
    marginTop: theme.spacing(2)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  error: {
    backgroundColor: theme.palette.error.dark
  }
}));

export default withMainTheme(Login);
